<template>
  <div>
    <div
      class="text-center q-pa-sm"
      style="height: 190px;"
    >
      <img
        :src="images[data.image]"
        style="width: auto; height: 100%; object-fit: contain;"
      >
    </div>

    <div class="row justify-center">
      <h6 class="q-my-none q-mr-sm">
        {{ $t(data.title) }}
      </h6>

      <q-badge
        v-if="data.caption"
        :label="$t(data.caption)"
        :color="data.captionColor || 'amber'"
        class="text-h6"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssistantObject',
  props: {
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      images: {
        scanBasketPlace: 'assets/terminal-icons-md/basket.png',
        scanPlace: 'assets/terminal/terminal5-medium.png',
        scanOrder: 'assets/terminal/terminal5-medium.png',
        scanStaticPlace: 'assets/terminal-icons-md/IMG-0940.png',
        scanDistributionPlace: 'assets/terminal-icons-md/IMG-0949.png',
        scanSortingPlace: 'assets/terminal-icons-md/IMG-0944.png',
        finish: 'assets/terminal-icons-md/IMG-0951.png'
      }
    }
  }
}
</script>
